/* eslint-disable no-underscore-dangle */
const addScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    window._wq = window._wq || [];
    // eslint-disable-next-line no-undef
    _wq.push({
      id: 'grvxee5ocj',
      options: {
        videoFoam: true,
        plugin: {
          cropFill: {src: '//fast.wistia.com/labs/crop-fill/plugin.js'},
        },
      },
    });
    addScript('https://fast.wistia.com/embed/medias/grvxee5ocj.jsonp');
    addScript('https://fast.wistia.com/assets/external/E-v1.js');
  };
};
