// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-despre-noi-js": () => import("./../src/pages/despre-noi.js" /* webpackChunkName: "component---src-pages-despre-noi-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informatii-destinate-publicului-js": () => import("./../src/pages/informatii-destinate-publicului.js" /* webpackChunkName: "component---src-pages-informatii-destinate-publicului-js" */)
}

